@import "../../../assets/scss/variables";

.tejesAboutArea {
    position: relative;
    z-index: 2;

    padding-top: 120px;
    padding-bottom: 120px;
    .aboutThumb {
        position: absolute;
        top: -52px;
        right: 0;
    }
}

.aboutInner {
    p {
        font-size: 16px;
        line-height: 27px;

        margin: 0;
        & + p {
            margin-top: 29px;
        }
    }
}

@media #{$xx-layout} {
    .tejesAboutArea .aboutThumb {
        width: 59%;
        
    }
}

@media #{$xlg-layout} {
    .tejesAboutArea {
        padding-bottom: 250px;
    }
    .tejesAboutArea .aboutThumb {
        top: 50%;

        width: 55%;

        transform: translateY(-50%);
    }
    .tejesAboutArea .aboutThumb img {
        width: 77%;
        margin-left:93px;
    }
}

@media #{$lg-layout} {
    .tejesAboutArea {
        padding-bottom: 150px;
    }
    .tejesAboutArea .aboutThumb {
        top: 50%;

        width: 55%;

        transform: translateY(-50%);
    }
    .tejesAboutArea .aboutThumb img {
        width: 77%;
        margin-left:93px;
    }
    .aboutInner p {
        font-size: 14px;
        line-height: 24px;
    }
}

@media #{$md-layout} {
    .tejesAboutArea {
        padding-bottom: 150px;
    }
    .tejesAboutArea .aboutThumb {
        position: inherit;
        top: auto;

        width: 100%;
        margin-top: 30px;

        transform: inherit;
    }
    .tejesAboutArea .aboutThumb img {
        width: 77%;
        margin-left:93px;
    }
    .tejesAboutArea {
        padding: 120px 0 90px;

        background: #F8FAFB none repeat scroll 0 0;
    }
    .tejesAboutArea .aboutShape {
        display: none;
    }
    .aboutInner p {
        font-size: 14px;
        line-height: 24px;
    }
}

@media #{$sm-layout} {
    .tejesAboutArea {
        padding-bottom: 150px;
    }
    .tejesAboutArea .aboutThumb {
        position: inherit;
        top: auto;

        width: 100%;
        margin-top: 30px;

        transform: inherit;
    }
    .tejesAboutArea .aboutThumb img {
        width: 77%;
        margin-left:93px;
    }
    .tejesAboutArea {
        padding: 120px 0 90px;

        background: #F8FAFB none repeat scroll 0 0;
    }
    .tejesAboutArea .aboutShape {
        display: none;
    }
    .aboutInner p {
        font-size: 14px;
        line-height: 24px;
    }
    .works .content p {
        font-size: 14px;
        line-height: 24px;
    }

    .tejesAboutArea {
        padding: 60px 0;
    }
    .aboutInner p {
        font-size: 14px;
        line-height: 24px;
    }
}
