@import "../../../assets/scss/variables";

.tejesBtn {
    font-size: 18px;
    font-weight: 500;
    line-height: 47px;

    position: relative;
    z-index: 1;

    display: inline-block;

    height: 47px;
    padding: 0 40px;

    transition: all 0.5s ease 0s;

    color: #FFF;
    border: none;
    border-radius: 24px;
    outline: none;
    background: rgba(0, 0, 0, 0) linear-gradient(-90deg, #FCCEC8, #E05941) repeat
    scroll 0 0;
    &::before {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        content: "";
        transition: all 0.5s ease 0s;

        opacity: 0;
        border-radius: 24px;
        background: rgba(0, 0, 0, 0) linear-gradient(90deg, #FCCEC8, #E05941) repeat
        scroll 0 0;
    }
    &:hover::before {
        opacity: 1;
    }
    &:focus {
        outline: none;
    }
    &:visited {
        color: #FFF;
    }
    &Light {
        position: relative;
        z-index: 1;

        transition: all 0.5s ease 0s;

        color: #E05941;
        background: linear-gradient(-90deg, #EEF3F6, #EEF3F6);
        &:visited {
            color: #E05941;
        }
        &::before {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            content: "";
            transition: all 0.5s ease 0s;

            opacity: 0;
            border-radius: 24px;
            background: linear-gradient(-90deg, #FCCEC8, #E05941);
        }
        &:hover::before {
            opacity: 1;
            color: #FFF;
        }
        &:hover {
            color: #FFF;
        }
    }
    &White {
        color: #E05941;
        background: #EEF3F6 none repeat scroll 0 0;
        &:hover {
            color: #FFF;
            background: $hover-color;
            &:visited {
                color: #FFF;
            }
        }
        &:visited {
            color: #E05941;
        }
    }
}
